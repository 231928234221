import { ApolloClient, ApolloClientOptions, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import getConfig from 'next/config'
import deepmerge from 'deepmerge'

const { serverRuntimeConfig } = getConfig()

const uriMap: Record<string, string> = {
  'localhost': 'http://localhost:8000/graphql',
  'creditunion.atomix.dev': 'https://cms.creditunion.atomix.dev/graphql',
}

export let uri = process.env.GRAPHQL_URL || process.env.NEXT_PUBLIC_GRAPHQL_URL

if (!uri) {
  console.error('Missing GraphQL url, attempting to resolve automatically')

  if (typeof window !== 'undefined' && window.location.host in uriMap) {
    uri = uriMap[window.location.host]
  } else {
    console.log('Failed to resolve GraphQL url, falling back to staging')
    uri = 'https://cms.creditunion.atomix.dev/graphql'
  }
}

const cache = new InMemoryCache({
  possibleTypes: serverRuntimeConfig.possibleTypes,
})

export type GetClientOptions = Omit<ApolloClientOptions<NormalizedCacheObject>, 'cache'>

const defaultOptions: GetClientOptions = process.env.NODE_ENV === 'production' ? {} : {
  headers: {
    'x-default-site-url': 'http://nginx:80',
  },
}

export const getClient = (options: GetClientOptions = {}) => {
  return new ApolloClient({
    cache,
    ...deepmerge({ uri, ...defaultOptions }, options),
  })
}

export const client = getClient()